// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attributions-js": () => import("./../../../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-app-js": () => import("./../../../src/pages/privacy-app.js" /* webpackChunkName: "component---src-pages-privacy-app-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-studies-breedon-js": () => import("./../../../src/pages/studies/breedon.js" /* webpackChunkName: "component---src-pages-studies-breedon-js" */),
  "component---src-pages-studies-js": () => import("./../../../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-page-template-js": () => import("./../../../src/templates/blog-page-template.js" /* webpackChunkName: "component---src-templates-blog-page-template-js" */)
}

