import React from "react";
import { Link } from "gatsby";

import imgLogo from "../../assets/images/sharptree_horizontal_logo.png";
import imgLogoWhite from "../../assets/images/sharptree_horizontal_logo_white.png";
import imgLogoIcon from "../../assets/images/sharptree_logo.png";
import imgLogoIconWhite from "../../assets/images/sharptree_logo_white.png";

const Logo = ({ white, variant, height, className = "", ...rest }) => {

  if (variant === "icon") {
    return (
      <Link to="/" className={`${className}`} {...rest}>
        {white ? (
          <img src={imgLogoIconWhite} alt="" height="50" />
        ) : (
          <img src={imgLogoIcon} alt="" height="50" />
        )}
      </Link>
    );
  } else {
    return (
      <Link to="/" className={`${className}`} {...rest}>
        {white ? (
          <img src={imgLogoWhite} alt="" height="50" />
        ) : (
          <img src={imgLogo} alt="" height="50" />
        )}
      </Link>
    );
  }
};

export default Logo;
