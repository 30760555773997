import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Title } from "../Core";
import Logo from "../Logo";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ dark, theme }) => dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
      &:hover {
        text-decoration: none;
        color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  a {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme, color }) => theme.colors.secondary} !important;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
      }
    }
  }
  a {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
    transition: 0.4s;
    padding: 0 3px;
    margin: 0 2.5px;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.primary} !important;
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "primaryPurple" : "greyBackground"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="2" md="2">
                <Logo variant="icon" white={isDark} />
              </Col>
              <Col lg="10" md="8" className="mt-5 mt-md-0">
                <Row>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        About
                      </TitleStyled>
                      <UlStyled dark={isDark ? 1 : 0}>
                        <li>
                          <Link to="/about">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/partners">
                            Our Partners
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Learn
                      </TitleStyled>
                      <UlStyled dark={isDark ? 1 : 0}>
                        <li>
                          <Link to="/solutions">
                            Solutions
                          </Link>
                        </li>
                        <li>
                          <Link to="/studies">
                            Case Studies
                          </Link>
                        </li>
                        <li>
                          <Link to="/blog">
                            Blog
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Help
                      </TitleStyled>
                      <UlStyled dark={isDark ? 1 : 0}>
                        <li>
                          <a href="https://support.sharptree.io" target="_blank" rel="noopener noreferrer">
                            Support Portal
                          </a>
                        </li>
                      </UlStyled>
                      <UlStyled dark={isDark ? 1 : 0}>
                        <li>
                          <Link to="/contact">
                            Contact Us
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Legal
                      </TitleStyled>
                      <UlStyled dark={isDark ? 1 : 0}>
                        <li>
                          <Link to="/attributions">Attributions</Link>
                        </li>
                        <li>
                          <Link to="/privacy">Privacy</Link>
                        </li>
                        <li>
                          <Link to="/terms-of-service">Terms of Service</Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="8" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; 2024 <a href="/">Sharptree LLC</a>, All Rights Reserved.</p>
              </Col>
              <Col sm="4" className="text-sm-right text-center">
                <span className="ml-sm-5">
                  <ul className="social-icons">
                    {/* <li>
                      <a href="https://twitter.com/sharptreellc" target="_blank" rel="noopener noreferrer">
                        <i className="icon icon-logo-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/company/sharptree" target="_blank" rel="noopener noreferrer">
                        <i className="icon icon-logo-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </span>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
